@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

body {
  background: #fafafb !important;
  margin: 0;
  font-family: "Inter", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grecaptcha-badge {
  visibility: hidden;
}

::placeholder {
  color: #929292 !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #929292 !important;
}

::-ms-input-placeholder {
  color: #929292 !important;
}

.style-area {
  background: #ffffff;
  border-radius: 15px;
  padding: 0 24px 24px;
  box-shadow: 0 0 6px rgba(161, 161, 161, 0.5);
}
.style-area-sticky {
  background: #ffffff;
  border-radius: 15px;
  padding: 1px 20px 1px;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 99; /* Ensure it appears above other content */
  transition: all 0.3s ease-in-out;
}
.box-shadow {
  box-shadow: 0 0 6px rgba(161, 161, 161, 0.5); /* X-offset, Y-offset, blur, spread, color */
}

.outline-purple {
  color: #000000;
  border: 2px solid #713fe0 !important;
  background-color: transparent;
}
.outline-purple:hover {
  color: #ffffff !important;
  border: 2px solid #713fe0 !important;
  background-color: #713fe0 !important;
}
a {
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.link {
  color: #000000 !important;
}

.link-black {
  color: #151726 !important;
}

.check-icon {
  font-size: 6rem;
}

.logo {
  width: 28px;
}

.mx-custom {
  margin-left: 3.9px !important;
  margin-right: 3.9px !important;
}

.skeleton {
  --glare-bg: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.4), transparent);
  position: relative;
  background-color: var(--bg);
  overflow: hidden;
}

.skeleton::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--glare-bg);
  transform: translateX(-100%);
  animation: skeleton-glare 1.75s infinite ease-out;
  z-index: 1;
}

@keyframes skeleton-glare {
  to {
    transform: translateX(100%);
  }
}

.card__img {
  width: 100%;
  height: 200px;
}

.fz-10 {
  font-size: 10px;
}
.fz-11 {
  font-size: 11px;
}
.fz-12 {
  font-size: 12px;
}

.fz-13 {
  font-size: 13px;
}

.fz-14 {
  font-size: 14px;
}

.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.br-10 {
  border-radius: 10px !important;
}
.br-15 {
  border-radius: 15px !important;
}
.title-product {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.color-subtitle {
  color: #a5a5a5;
}

.cursor-pointer {
  cursor: pointer;
}

.offcanvas.offcanvas-end {
  background-color: #151726;
  color: #f8f9fa;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slick-dots li button:before {
  color: #fff !important;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
}

.slick-slide div {
  outline: none;
}

button.btn-close {
  background-color: #f8f9fa !important;
}

.sosmed-icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.wrapper-link {
  width: auto;
  text-align: center;
  background: #f8f9fa !important;
  color: #151726 !important;
  padding: 5px 7px !important;
  border-radius: 10px;
  font-size: 14px !important;
}

.set-height {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.list-style-none {
  list-style-type: none;
}

input {
  border: 2px solid #713fe0 !important;
  color: #000000 !important;
  background: #ffffff !important;
}

.bg-form {
  background: #713fe0 !important;
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
}

.text-darkWhite {
  color: #dcdcdc !important;
}

.btn-blue {
  background: linear-gradient(180deg, #5872ff, #465ede);
  color: #000000 !important;
}
.modal-content {
  background: #f2f5fe !important;
  color: #000000 !important;
}
.modal-footer {
  border: none !important;
}

.accordion-item,
.accordion-button {
  border: 1px solid #713fe0 !important;
  color: #000000 !important;
}

button.accordion-button::after {
  background-image: url("https://s3-id-jkt-1.kilatstorage.id/assets.mobacash.com/arrow-bottom_1688374440670.webp");
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.img-success {
  width: 200px;
}

.w-title {
  width: 40%;
}
.card-history {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.item-icon{
  border-radius: 10px 10px 0px 10px;
}
@media (max-width: 768px) {
  .title-product {
    font-size: 10px !important;
  }
  .outline-purple {
    color: #000000;
    border: 1px solid #713fe0 !important;
    background-color: transparent;
  }
  .outline-purple:hover {
    color: #ffffff !important;
    border: 1px solid #713fe0 !important;
    background-color: #713fe0 !important;
  }
  input {
    border: 1px solid #713fe0 !important;
    color: #000000 !important;
    background: #ffffff !important;
  }
}
