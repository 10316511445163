.text-primary2 {
  color: #27a8e1;
}
.cardGame {
  height: 100px !important;
  background: #202233 !important;
  border-radius: 10px;
}
.cardGames {
  color: #fff;
  border-radius: 10px;
  border: 2px solid transparent;
  background-image: url("../../assets/img/cardBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  transition: box-shadow 0.3s ease;
}

.cardGames:hover {
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);  */
  border: 2px solid #713fe0 !important;
}

.imgGames {
  border-radius: 10px 10px 0px 10px;
  height: 60px;
  width: 60px;
}

.slick-track {
  margin-left: 0 !important;
}

.arrow {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  width: 40px !important;
  height: 40px !important;
  background-color: #000 !important;
  color: #fff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  z-index: 1 !important;
}

.arrow.left {
  left: 20px !important;
}

.arrow.right {
  right: 20px !important;
}

.arrow.top-right {
  top: 20px !important;
  transform: translateY(0) !important;
}
@media (max-width: 768px) {
  .imgGames {
    border-radius: 10px 10px 0px 10px;
    height: 48px;
    width: 48px;
  }
}
