.cardGame2 {
  height: 180px !important;
  background: #202233 !important;
  border-radius: 12px;
}
.cardGames2 {
  height: 160px !important;
  background: #ffffff !important;
  text-align: center;
  margin-top: 60px;
  padding-top: 60px;
  border: 2px solid #713fe0 !important;
  border-radius: 10px;
}

.imgGames2 {
  border-radius: 20px;
  border-radius: 10px;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  margin: auto;
  width: 120px !important;
  height: 120px !important;
  object-fit: cover;
}

.btnGame {
  background: #713fe0 !important;
  font-size: 12px !important;
  border: none !important;
  color: #ffffff !important;
  font-weight: bold !important;
  border-radius: 10px !important;
}

.slick-track {
  margin-left: 0 !important;
}

.arrow {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  width: 40px !important;
  height: 40px !important;
  background-color: #000 !important;
  color: #fff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  z-index: 1 !important;
}

.arrow.left {
  left: 20px !important;
}

.arrow.right {
  right: 20px !important;
}

.arrow.top-right {
  top: 20px !important;
  transform: translateY(0) !important;
}
@media (max-width: 768px) {
  .cardGames2 {
    height: 120px !important;
    background: #ffffff !important;
    text-align: center;
    margin-top: 60px;
    padding-top: 60px;
    border: 1px solid #713fe0 !important;
    border-radius: 10px;
  }

  .imgGames2 {
    border-radius: 10px;
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    margin: auto;
    width: 80px !important;
    height: 80px !important;
    object-fit: cover;
  }
  .detailGames2 {
    margin-top: -20px;
  }
}
