.footerCustom {
  background-color: #ffffff;
}
.iconWrapper {
  border: 2px solid #000000 !important;
  padding: 4px;
  border-radius: 10px;
}
.textDesc {
  margin-bottom: -80px;
  z-index: 99;
  padding-top: 100px;
}
