.nav-tabs {
  border: none !important;
}
.nav-tabs .nav-link {
  border: 2px solid #713fe0 !important;
  border-radius: 10px;
  color: #000000 !important;
  width: 165px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #713fe0 !important;
  color: #ffffff !important;
  border: 2px solid #713fe0 !important;
  border-radius: 10px;
}

.nav-text {
  font-size: 16px !important;
  color: black;
}
@media (max-width: 768px) {
  .nav-tabs .nav-link {
    border: 1px solid #713fe0 !important;
    border-radius: 10px;
    color: #000000 !important;
    width: 165px;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background: #713fe0 !important;
    color: #ffffff !important;
    border: 1px solid #713fe0 !important;
    border-radius: 10px;
  }
}
