.btnChat {
  margin-bottom: -3px;
  border: 1px solid #713fe0 !important;

  background-color: #713fe0;
  color: white;
  border-radius: 8px 8px 0px 0px;
}

/* Hide the dropdown arrow */
.btnChat .dropdown-toggle::after {
  display: none;
}
.btnItem:hover {
  background-color: #318ac2;
}
