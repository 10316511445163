.searchProduct{
  width: 30%;
}
.list-number {
  background-image: url("../../assets/img/bgNum2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 50%;
  color: #ffffff;
}
.bgForm {
  background-image: url("../../assets/img/bgSubnav.png");
  /* /* background-repeat: no-repeat; */
  background-size: 110% 110%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
  border: 2px solid #713fe0 !important;
}

.cover {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  max-height: 399px;
  /* object-fit: cover; */
}
.btn-purple {
  background-color: #713fe0;
  border-radius: 10px;
  border: 2px solid transparent;
  font-size: 14px;
  /* margin: auto; */
}
.btn-purple-outline {
  background-color: transparent;
  border: 2px solid #713fe0;
}
.btn-purple:hover {
  color: #000000;
  background: #f2f5fe !important;
  border: 2px solid #713fe0 !important;
}
.btn-purple-outline:hover {
  color: #000000;
  background: #f2f5fe !important;
  border: 2px solid #713fe0 !important;
}

@media (max-width: 992px) {
  .bgForm {
    background: transparent;
    border: transparent !important;
    color: #000000;
  }
  .cover {
    max-height: 100%;
  }
  .banner {
    max-height: 100%;
    width: 60px !important;
    height: 60px !important;
  }
  .btn-purple {
    background-color: #713fe0;
    border-radius: 10px;
    border: 1px solid transparent;
    font-size: 14px;
    /* margin: auto; */
  }
  .btn-purple-outline {
    background-color: transparent;
    border: 1px solid #713fe0;
  }
  .btn-purple:hover {
    color: #000000;
    background: #f2f5fe !important;
    border: 1px solid #713fe0 !important;
  }
  .btn-purple-outline:hover {
    color: #000000;
    background: #f2f5fe !important;
    border: 1px solid #713fe0 !important;
  }
  .input-custom {
    font-size: 14px;
    border: 1px solid #ffffff;
    background-color: transparent !important;
    border-radius: 8px;
    padding: 8px 15px 8px 15px;
    /* padding-left: 20px; */
  }
  .radio-btn {
    height: auto !important;
    padding: 15px;
    flex-direction: column;
    text-align: center;
  }

  .icon-container {
    order: 0; 
    margin-bottom: 1rem;
  }

  .text-container {
    order: 1;
  }

  .check-custom {
    order: 2;
  }
  .searchProduct{
    width: 100%;
  }
  .card-topup input:checked + .radio-btn {
    border: 1px solid #713fe0 !important;
    background: #ffffff;
  }
  .card-payment input:checked + .radio-btn2 {
    border: 1px solid #713fe0 !important;
    background: #f8f8f8;
    /* min-height: 70px; */
  }
}

.input-custom {
  font-size: 14px;
  border: 2px solid #ffffff;
  background-color: transparent !important;
  border-radius: 8px;
  padding: 8px 15px 8px 15px;
  /* padding-left: 20px; */
}

select option {
  margin: 40px;
  background-color: #ffffff !important;
  color: #000000;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
}

.banner {
  width: 85px;
  height: 85px;
  object-fit: cover;
  border-radius: 15px;
}
.bannerDetail {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 10px;
}
.input-group-text {
  background-color: rgba(255, 255, 255, 0.08);
}

.radio-custom {
  display: none;
}

.radio-btn {
  cursor: pointer;
  border: 1px solid #9a9a9a;
  background-color: #e1e1e1;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.radio-btn > .check-custom {
  color: #000000;
  font-size: 16px;
  border-radius: 50px;
  padding: 3px;
  transition: 0.2s;
  pointer-events: none;
  opacity: 0;
  overflow: hidden;
}
.radio-btn2 {
  position: relative;
  cursor: pointer;
  text-align: center;
  border: 1px solid #9a9a9a;
  background-color: #e1e1e1;
  border-radius: 10px;
  height: 80px;
}

.radio-btn2 > .check-custom {
  color: #000000;
  font-size: 16px;
  border-radius: 50px;
  padding: 3px;
  transition: 0.2s;
  pointer-events: none;
  opacity: 0;
  overflow: hidden;
}

.card-topup {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-topup input:checked + .radio-btn > .check-custom {
  opacity: 1;
}

.card-topup input:checked + .radio-btn {
  border: 2px solid #713fe0;
  background: #ffffff;
}
.card-topup input:checked + .radio-btn .activeText {
  color: #713fe0;
}

.card-topup input:checked + .radio-btn > .check-custom {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

.card-payment {
  cursor: pointer;
  /* min-height: 70px; */
}
.card-payment input:checked + .radio-btn2 > .check-custom {
  opacity: 1;
}

.card-payment input:checked + .radio-btn2 {
  border: 2px solid #713fe0;
  background: #f8f8f8;
  /* min-height: 70px; */
}

.card-payment input:checked + .radio-btn2 > .check-custom {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

.check-custom {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: -4px;
}

.iconItem {
  width: 20px;
  /* height: 100%; */
}

.payment-logo {
  width: 50px;
  height: auto;
}

.toastError {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999;
}

.recaptcha-error {
  border: 1px solid #dd4b39;
  padding: 0 0;
}

.recaptcha-error-message {
  color: #dd4b39;
  font-size: 12px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  flex: 1;
  background: #ffffff !important;
  border: 1px solid #713fe0 !important;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
}
select::-ms-expand {
  display: none;
}
.select {
  position: relative;
  display: flex;
  line-height: 2.3;
  background: #5c6664;
  border-radius: 20px;
}
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}

.custom-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
