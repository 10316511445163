.imgCarousel {
  width: 100%;
  cursor: pointer;
  border-radius: 20px;
}

.carouselCp {
  width: 100%;
  height: 350px;
  background-color: #202233;
  position: relative;
}

.carousel-dots {
  text-align: center;
  padding-top: 10px;
}

.carousel-dots .dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: rgba(84, 81, 214, 0.5);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease, width 0.3s ease;
}

.carousel-dots .dot.active {
  background-color: #713fe0;
  width: 30px;
  border-radius: 10px;
}

.coverbannerImg {
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.coverbannerImg.inactive {
  filter: blur(5px);
  opacity: 0.7;
  transform: scale(0.95);
}

.coverbannerImg.active {
  opacity: 1;
  transform: scale(1); /* Updated scale for active state */
}

.bannerImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  transition: transform 0.5s ease-in-out;
}
@media (max-width: 992px) {
  .coverbannerImg {
    height: 260px;
    margin: auto;
    }
  .bannerImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    transition: transform 0.5s ease-in-out;
  }

}
@media (max-width: 768px) {
  .coverbannerImg {
    height: 160px;
  }

}
@media (max-width: 576px) {
  .coverbannerImg {
    height: 180px;
  }


}
@media (max-width: 768px) {
 
  .bannerImg {
    object-fit: cover;
    border-radius: 20px;
    transition: transform 0.5s ease-in-out;
  }

  .carousel-dots {
    margin-top: 10px; /* Adjusted margin for dots on smaller screens */
  }

  .carousel-dots .dot {
    height: 10px;
    width: 10px;
    border-radius: 10px;
  }

  .coverbannerImg.inactive {
    filter: blur(0px);
    opacity: 0;
    transform: scale(0.95);
  }
}
