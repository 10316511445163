.btn-purple2 {
  background-color: #713fe0;
  color: white;
  border-radius: 10px;
  border: 2px solid transparent;
  /* margin: auto; */
}
.btn-purple2:hover {
  background: #6230cd !important;
  color: white;
  border: 1px solid #713fe0 !important;
}
