.navbarCustom {
  background-color: #ffffff;
  border-radius: 25px;
  margin-top: 10px;
  z-index: 99999;
  box-shadow: 0 0 15px rgba(161, 161, 161, 0.5); /* X-offset, Y-offset, blur, spread, color */
}

.bg-search {
  background-color: #f9f9f9 !important;
  width: 300px;
  color: #000000;
  padding: 6px 0px 6px 0px;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}
.text-purple {
  color: #713fe0 !important;
}
.inputCustom {
  background-color: #202233 !important;
  border: 1px solid #202233 !important;
  color: #fff !important;
}

.borderNavbar {
  height: auto;
  margin: 6px 0px 6px 0px; /* Adjust this value to change the border height */
  border-left: 1px solid #d9d9d9;
}

@media (max-width: 768px) {
  .my-modal {
    margin: 30px !important;
  }
  .searchIcon {
    /* position: absolute; */
    font-size: 18px;
    display: flex;
    justify-content: center;
  }
  .bg-search {
    background-color: transparent !important;
    width: 50px;
    /* margin-right: 0px; */
  }
}
